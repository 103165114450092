import PeopleSection from "./peoplesection";
import BookSection from "./booksection";
import Quote from "./quote";
import CategorySidebar from "./categorysidebar";
import { get_containing, get_containing_filt } from "./utils";
import categories from "../categories";
// import { useParams } from "react-router-dom";
import SEO from "../components/seo";

function BooksRoot({ books, other_people, other_books, area, pagination }) {
  let readable_area = area
    ? categories["books_url"][area]
    : "Todas as recomendações";

  return (
    <div className="px-3 md:px-10 lg:px-24 xl:px-60">
      <SEO
        title={"Recomendações de livros - " + readable_area}
        description={
          "Não sabe o que ler? Descubra os livros recomendados pelas pessoas mais bem sucedidas do mundo ( " +
          readable_area +
          " )."
        }
      />
      <div className="grid lg:grid-cols-2 grid-cols-1 text-center lg:text-left items-center">
        <div className="text-2xl lg:text-4xl font-light text-blue-800 ">
          <h1>{readable_area}</h1>
        </div>
        <div className="text-xl font-light text-blue-800 my-8 lg:my-16 place-items-center grid">
          <div className="w-3/4">
            <h2>
              Aqui voce vai descobrir o que as pessoas mais bem sucedidas do
              mundo estão lendo
            </h2>
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 lg:grid-cols-6">
          <div className="col-span-1 lg:col-span-4">
            <BookSection
              books={books}
              cta="Todos os livros"
              pagination={pagination}
            />
          </div>
          <div className="col-span-1 lg:col-span-2">
            <CategorySidebar
              categories={categories["book_categories"]}
              base_url="categoria"
            />
          </div>
        </div>
      </div>

      <BookSection books={other_books} cta="Adicionados Recentemente" />
      <Quote />
    </div>
  );
}

export default BooksRoot;
