// import people from "../../people";
// import books from "../../books";
import BooksRoot from "../components/booksroot";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { normalize_graphql_data } from "../components/utils";

const CategoriaPage = ({ props, data, pageContext }) => {
  let target_books = normalize_graphql_data(data.target.nodes);
  let other_books = normalize_graphql_data(data.other_books.edges);
  let other_people = normalize_graphql_data(data.other_people.edges);
  let area = pageContext.slug;

  let paginationData = {
    currentPage: pageContext.currentPage,
    numPages: pageContext.numPages,
    base: `/categoria/${area}`,
  };

  return (
    <Layout pageTitle="Home Page">
      <BooksRoot
        books={target_books}
        other_people={other_people}
        other_books={other_books}
        area={area}
        pagination={paginationData}
      />
    </Layout>
  );
};

export const query = graphql`
  query CategoriaQuery($slug: [String], $skip: Int, $limit: Int) {
    other_people: allPeepsJson(
      limit: 12
      skip: 34
      sort: { fields: person_ranking }
    ) {
      edges {
        node {
          ...PeepsJsonFragment
        }
      }
    }
    other_books: allBeeksJson(
      limit: 12
      skip: 80
      sort: { fields: recommended_volume, order: DESC }
    ) {
      edges {
        node {
          ...BeeksJsonFragment
        }
      }
    }
    target: allBeeksJson(
      filter: { book_attributes_slugs: { in: $slug } }
      limit: $limit
      skip: $skip
      sort: { fields: recommended_volume, order: DESC }
    ) {
      totalCount
      nodes {
        ...BeeksJsonFragmentComplete
      }
    }
  }
`;

export default CategoriaPage;
